import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby";

const IndexPage = ({
  data: {
    site,
  },
}) => {
    return (
  <Layout>
    <Seo title="About The Nerds" />
    <h2>About the Nerds &darr;</h2>
    
  </Layout>
    )
}

export default IndexPage
export const pageQuery = graphql`
  query aboutPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`